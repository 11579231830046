import React, { memo } from "react"
import PurpleSolidButton from "../../../components/Buttons/PurpleSolidButton"
const StuckPixelFix = ({ startRepair }) => {
  return (
    <div className="px-3 prose-sm prose sm:prose lg:prose-lg xl:prose-xl md:px-0">
      <p className="lead">
        Fai clic sul pulsante <code>Ripara</code> e lo schermo diventerà nero e
        vedrai un quadrato con sfumature al suo interno. Trascina quel quadrato
        e portalo sui pixel bloccati e attendi in questo modo per 10-15 minuti.
      </p>
      <blockquote>
        Non dimenticare di regolare le impostazioni di alimentazione in modo che
        lo schermo non si spenga.
      </blockquote>
      <p>
        È possibile premere{" "}
        <span className="px-2 py-1 text-sm font-bold text-red-800 bg-red-100 border-red-300 rounded-sm shadow-sm">
          ESC
        </span>{" "}
        per terminare il processo di riparazione dei pixel bloccati e uscire.
      </p>
      <div className="text-center">
        <PurpleSolidButton text="Ripara" onClick={startRepair} />
      </div>
    </div>
  )
}
export default memo(StuckPixelFix)
